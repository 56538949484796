import Header from "../RRCHeader";
import "./ContactUs.css";
import loc from "../../../assets/images/location.png";
import contact from "../../../assets/images/contact.png";
import mail from "../../../assets/images/mail.png";
const ContactUs = () => {
  return (
    <div className="contact_main flt">
      <Header />
      <div className="contactus_main flt">
        <div className="container">
          <div className="contact_details flt">
            <span>CONTACT DETAILS</span>
          </div>
          <div className="contact_content flt">
            Regional Resources Centre for Folk Performing Arts was built with
            the help of the grants offered by the Ford Foundation. The saga of
            the RRC marks the culmination of international recognition that this
            pioneering institution of learning acquired.
          </div>
          <div className="cont_box flt">
            <div className="row">
              <div className="col-sm-4">
                <div className="main_box flt">
                  <div className="icon_main">
                    <span>
                      <img src={loc} />
                    </span>
                  </div>
                  <div className="main_content_box">
                    Regional Resources centre for Folk Performing Arts (RRC-
                    MAHE), MGM College Campus, Kunjibettu, Udupi, Karnataka,
                    India - 576102
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="main_box flt">
                  <div className="icon_main">
                    <span>
                      <img src={contact} />
                    </span>
                  </div>
                  <div className="main_content_box">
                    Dr. B. Jagadish Shetty (Administrative Officer) <br /> + 91
                    9448868868 <br />
                    <br /> Dr. Aruna Kumara S R (Research Associate) <br />
                    +91 9449471449
                    <br />
                    <br /> Office - 0820 - 2521159
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="main_box flt">
                  <div className="icon_main">
                    <span>
                      <img src={mail} />
                    </span>
                  </div>
                  <div className="main_content_box">rrc.udupi@manipal.edu</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="map_box flt">
          <div className="container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3882.0622028727544!2d74.76424811492289!3d13.346406790610818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbcbb5a4a2027c3%3A0xbaca5987973bc727!2sMahatma%20Gandhi%20Memorial%20College%20Udupi!5e0!3m2!1sen!2sin!4v1653902446021!5m2!1sen!2sin"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
